import movie from "../assets/moviesnag.JPG";
import blackstories from "../assets/blackstories.png";
import palette from "../assets/paletteproducer.png";
import feud from "../assets/feud.jpg"

const projects = [
  {
    id: 5,
    title: "Black Stories Film Club Website",
    description:
      "A site I built with a graphic designer to help our movie club choose and keep track of the movies on our 'to watch' list.",
    repoLink: "https://github.com/sschipke/black-stories-fe",
    deployLink: "https://blackstories.netlify.app",
    image: blackstories,
    role: "Front-End Engineer",
    weekDuration: "Ongoing",
    technologies1: ["React", "Redux", "React Router"],
    technologies2: ["Hooks", "MovieDB API"],
  },
  {
    id: 4,
    title: "Palette Producer",
    description:
      "I built this fullstack app with another developer to help create and save color palettes for various projects. It also helped me with logging in users and working with my own API",
    repoLink: "https://github.com/sschipke/palette-producer",
    deployLink: "https://paletteproducer.netlify.app/",
    image: palette,
    role: "Front-End Engineer / UI Designer",
    weekDuration: 2,
    technologies1: ["React", "Redux", "Router", "Jest/Enzyme"],
    technologies2: ["Node", "Express", "Knex", "Travis CI"],
  },
  {
    id: 1,
    title: "Movie Snag",
    description:
      "This was an app made in conjunction with another developer that allows users to create an account, log in, see information about various movies and favorite the ones they are interested in. It was planned an created over a two-week sprint and uses React, SCSS, Redux, the Movie Database API, and heavy testing using Jest and Enzyme. We also deployed the backend that stores the users, and which movies they have favorited to Heroku which also hosts the front end (on a separate server). Each movie can be clicked on for more information and can also be favorited and saved for later.",
    repoLink: "https://github.com/sschipke/movie-tracker",
    deployLink: "https://movie-snag.herokuapp.com/",
    image: movie,
    role: "Full-Stack Engineer / UI Designer",
    weekDuration: 2,
    technologies1: ["React", "Redux", "MovieDB API"],
    technologies2: ["Node.js", "Express", "PostgreSQL", "Jest"],
  },
  {
    id: 3,
    title: "Feud: FE vs BE",
    description:
      "A tech twist on the classic 'Family Feud' game show. Made in collaboration with four other developers.",
    repoLink:
      "https://github.com/sschipke/gameTime",
    deployLink:
      "https://sschipke.github.io/gameTime/",
    image: feud,
    role: "Front-End Engineer / UI Designer",
    weekDuration: 2,
    technologies1: ["jQuery", "Webpack", "SASS", "Mocha/Chai"],
    technologies2: [],
  }
];

export default projects;
