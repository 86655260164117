import React from 'react';
import './Footer.scss';

const Footer = () => {
	return (
		<footer className='Footer' name='Footer'>
			Thanks for Visiting!
		</footer>
	);
};

export default Footer;
