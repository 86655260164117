const jobs = [
    {
    id: 0,
    title: "Software Engineer",
    company: "Nike",
    location: "Remote",
    dateRange: "February 2022 - Present",
    url: "https://nike.com",
    description: [
      "Worked synchronously and asynchronously with engineers and product managers to improve and maintain distributed Point of Sales system. Also worked on deployment, pipeline, and platform operations for this system as well as troubleshooting and emergency service.",
    ],
  },
  {
    id: 1,
    title: "Software Engineer",
    company: "Conga",
    location: "Broomfield, CO",
    dateRange: "March 2020 - February 2022",
    url: "https://conga.com",
    description: [
      "Worked collaboratively with other engineers and product managers and owner to add customer requested features, fix bugs, and continuously improved the company's eSignature product.",
    ],
  },
  {
    id: 2,
    title: "Front-End Engineering Student",
    company: "Turing School",
    location: "Denver, CO",
    dateRange: "June 2019 - Jan 2020",
    url: "https://turing.io",
    description: [
      "Collaborated in fast-paced, cross-functional teams for more than 60 hours a week for 9 months building a variety of interesting and meaningful projects.",
      "Use predominantly front end (React, Jest, Mocha, etc.) and some backend technologies to build fully tested, scaling, and feature-rich applications."
    ],
  },
  {
    id: 3,
    title: "Auxilar de Conversación (Language Teacher)",
    company: "Spanish Ministry of Education",
    location: "Basauri, Spain",
    dateRange: "Sept 2016 - June 2017",
    url: "https://www.educacionyfp.gob.es/eeuu/convocatorias-programas/convocatorias-eeuu/nalcap.html",
    description: [
      "Collaborated with instructional staff to brainstorm and create lesson content for Spanish-speaking and Basque-speaking students ages 12-18."
    ],
  }
];

export default jobs;
