import React from "react";
import "./AboutMe.scss";
import headshot from "../../assets/headshot.jpg";
import Fade from "react-reveal/Fade";

const AboutMe = () => {
  return (
    <main className="AboutMe" name="AboutMe">
      <Fade cascade>
        <Fade bottom></Fade>
        <section className="about-box">
          <section className="about-description">
            <h2>
              <span>01. </span>About Me
            </h2>
            <p>
              I'm a curious human being from South Dakota who is constantly learning and growing. Currently working as a Software Engineer at
              {" "}
              <a
                href="https://nike.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="hyperlink"
              >
                Nike
              </a>{" "}
              with a passion for creating, building, and collaborating using various web technologies.
            </p>
            <p>
              After graduating from{" "}
              <a
                href="https://www.sdstate.edu/"
                target="_blank"
                rel="noopener noreferrer"
                className="hyperlink"
              >
                South Dakota State University
              </a>
              working in healthcare, and teaching in Spain, I joined the intensive Front-End Engineering program at{" "}
              <a
                href="https://turing.io"
                target="_blank"
                rel="noopener noreferrer"
                className="hyperlink"
              >
                Turing School of Software & Design{" "}
              </a>{" "}
              where I worked on fast-paced, cross-functional teams for 8 months
              on a wide array of projects using various JavaScript frameworks and tools.
            </p>
            <p>Here are a few technologies I've been working with recently:</p>
            <section className="skills">
              <ul>
                <li>
                  <span>▹</span> JavaScript <span className="es6">(ES6+)</span>
                </li>
                <li>
                  <span>▹</span> Java <span className="es6">(8+)</span>
                </li>
                <li>
                  <span>▹</span> AWS
                </li>
              </ul>
              <ul>
                <li>
                  <span>▹</span> NodeJS
                </li>
                <li>
                  <span>▹</span> Spring
                </li>
                <li>
                  <span>▹</span> Splunk
                </li>
              </ul>
            </section>
          </section>
          <section>
            <img src={headshot} alt="Headshot of Scott" />
          </section>
        </section>
      </Fade>
    </main>
  );
};

export default AboutMe;
