import React from "react";
import "./Home.scss";
import { Link } from "react-scroll";
import Fade from "react-reveal/Fade";

const Home = () => {
  const name = "Scott Schipke";
  const resumeUrl = "https://resume.creddle.io/resume/cbcbo4nny6e";

  return (
    <>
      <Fade cascade delay={600}>
        <section className="title-section">
          <p className="introduction">Hello! My name is</p>
          <h1>{name}.</h1>
          <h1 className="sub-header-title">Creator & Collaborator</h1>
          <p className="description-title">
            I'm a fullstack{" "}
            <span>
              <strong>Software Engineer</strong>
            </span>{" "}
            base in New York City. My areas of interest include UI/UX,
            Cybersecurity, Infrastructure, and many more.
          </p>
          <div className="home-buttons-container">
            <Fade bottom cascade delay={800}>
              <Link
                to="Footer"
                spy={true}
                smooth={true}
                ignoreCancelEvents={true}
                duration={1200}
                className="button-link"
              >
                <button className="contact-button">Get In Touch</button>
              </Link>
              <a
                href={resumeUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="button-link"
              >
                <button className="contact-button">Resume</button>
              </a>
            </Fade>
          </div>
        </section>
      </Fade>
      <div className="orb"></div>
    </>
  );
};

export default Home;
